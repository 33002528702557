import React from 'react'
import Link from 'gatsby'

import Layout from '../components/layout'
import Seo from '../components/seo'

import PageHero from '../components/pageHero'
import Blockquote from '../components/sections/blockquote'
import ContentCenter from '../components/sections/contentCenter'

const Wordpress = () => (
	<Layout>
		<Seo title='Получи готовый сайт на Wordpress' />
		<PageHero
			title='Закажи сайт на WordPress'
			description='Если ты уже готов или готова, то нажимай на кнопку, отправляй заявку и мы сделаем тебе сайт.'
			button='Отправить заявку'
			link='https://forms.gle/2jNEUfn4jofqZphz5'
			image='/images/clouds-001-1920.jpg'
		/>
		<Blockquote
			content='На этой странице мы подробно рассказываем о процессе создания сайта, о том, что от тебя потребуется и какой веб-сайт ты получишь в итоге	.'
		/>
		<ContentCenter
			subtitle='Websites'
			title='Разработка сайта'
			intro='Вот если тебе нужно быстро и недорого получить полноценный сайт, то прямо здесь ты его и получишь. Мы делаем сайт на базе WordPress и готового премиум шаблона. За счет этого разработка сайта быстрая и недорогая.'
		>

			<h2>Какой сайт ты получишь</h2>

			<p>В первую очередь это полноценный и полностью твой сайт. Т.е. ты не зависишь от разных там конструкторов, их абоненсткой платы и ограничений. Твой сайт полностью под твоим контролем, вплоть до того, что ты можешь его продать.</p>

			<h3>Шаблоны от WPshop</h3>

			<p>Мы выбрали шаблоны от WPshop, поскольку они изначально хорошо оптимизированы для быстрой загрузки страниц и поисковиков, т.е. проблем с SEO никаких. Кроме этого они очень функциональны и легко настраиваются прямо из админки сайта (или, по другому, панели управления). Кроме шаблонов ребята выпускают хорошие плагины, расширяющие функции сайта и они тоже не нагружают сайт, что очень хорошо. В общем, нам нравится то, что они делают.</p>

			<p>У них есть шаблоны для контентных сайтов, есть шаблон для интернет-магазина. Он называется BONO.</p>

			<img src='/images/wpshop-screenshot-pc-1.jpg' />

			<p>Дизайн шаблонов вполне можно использовать как есть, он лаконичный, а где-то даже простоватый. Но это не плохо, потому что главное на сайте - это все же контент. Тем не менее дизайн можно настроить под свой сайт, сделать его уникальным. Ты вполне можешь поиграться с этими настройками самостоятельно или попросить, например, нас (это, конечно, стоит дополнительных денег, имей в виду).</p>

			<p><a href='https://wpshop.ru/?partner=10577' rel='nofollow' target='_blank'>Смотри и выбирай шаблоны на сайте разработчика.</a></p>

			<p>Чтобы у тебя было визуальное представление о шаблоне, посмотри еще и видео. Правда оно древнее - 2019 года, но общее представление о шаблоне можно получить. В видео речь идет о шаблоне REBOOT.</p>

			<div class="aspect-w-16 aspect-h-9">
				<iframe src="https://www.youtube.com/embed/VpeQxnTkj7Q" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
			</div>

			<p>Еще заметь, что шаблоны <a href='https://wpshop.ru/?partner=10577' rel='nofollow' target='_blank'>WPshop.ru</a> постоянно улучшаются и поддерживаются в актуальном состоянии, что делает их хорошим выбором для твоего сайта, который будет расти и развиваться не один год.</p>

			<h2>Что потребуется от тебя</h2>

			<p>Здесь коротко напишу, что предстоить сделать тебе. Поскольку сайт твой, тебе тоже нужно хоть что-то сделать. Но не пугайся, это вполне по силам каждому.</p>

			<p>Записывай</p>

			<ul>
				<li>Придумать домен. Он должен быть уникальным, без истории и... Ну, в общем, есть некоторые рекомендации о том, каким должен быть домен. Это все мы расскажем.</li>
				<li>Предоставить данные. Это нужно для регистрации аккаунтов для тебя, покупки домена(ов), шаблона, плагинов и т.п. Если хочешь ты самостоятельно можешь все это зарегистрировать.</li>
				<li>Всё оплатить. Домен(ы), хостинг, шаблон, плагины и нашу работу в итоге.</li>
			</ul>

			<h2>Стоимость сайта</h2>

			<p>Вот тебе расчет по стоимости сайта. Самый минимум. Расчет ориентировочный, дается лишь как информация и не является оффертой. Этот расчет для того, чтобы у тебя было предсталение по суммам.</p>

			<table className='table-auto'>
				<thead>
					<tr>
						<th>Что</th>
						<th>Сколько</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>Домен в зоне RU</td>
						<td>119 руб.</td>
					</tr>
					<tr>
						<td>Хостинг на 1 год</td>
						<td>2'611 руб.</td>
					</tr>
					<tr>
						<td>SSL-сертификат</td>
						<td>бесплатно</td>
					</tr>
					<tr>
						<td>WordPress</td>
						<td>бесплатно</td>
					</tr>
					<tr>
						<td>Шаблон для WordPress</td>
						<td>3'800 руб.</td>
					</tr>
					<tr>
						<td>Плагин Clearfy для WordPress</td>
						<td>970 руб.</td>
					</tr>
					<tr>
						<td>Заплатить Еноту</td>
						<td>3'200 руб.</td>
					</tr>
					<tr>
						<td className='font-bold'>Итого: стоимость сайта</td>
						<td className='font-bold'>10'700 руб.</td>
					</tr>
				</tbody>
			</table>

			<p>Цены проверены 21.12.2022 г. Мы не отслеживаем цены каждый день, имей в виду, поэтому без претензий. Если что-то поменяется, то работаем по актуальным ценам. Но не переживай, обычно повышения цены незначительные и случаются не часто.</p>

			<h2>Алгоритм действий или как все происходит</h2>

			<p>Нам с тобой нужно сделать все быстро и без лишних заморочек, поэтому все максимально просто:</p>

			<ul>
				<li>Отправляешь Заявку -> Мы отвечаем и договариваемся о деталях.</li>
				<li>Регистрируем аккаунты -> Покупаем домен, хостинг, шаблон и плагин.</li>
				<li>Устанавливаем WordPress и настраиваем сайт.</li>
				<li>Передаем тебе доступы -> Ты отправляешь нам денежку. Все! :)</li>
			</ul>

			<p>Далее подробно описываем весь процесс, чтобы все было максимально прозрачно и было понятно чего ожидать.</p>

			<h3>Заявка</h3>

			<p>Ты нажимаешь на кнопку «Отправить заявку» и всё начинается. Далее заполняешь поля в форме и отправляешь. Мы получаем и отвечаем тебе на E-Mail. В переписке обговариваем все детали. Обычно мы отвечаем в течение 24 часов.</p>

			<h3>Аккаунты и данные</h3>

			<p>Сайт твой, поэтому мы все регистрируем на твое имя. Соответственно потребуются твои данные: E-Mail, ФИО. Для регистрации аккаунтов нужны только E-Mail и ФИО, как правило. </p>

			<p>Для регистрации домена потребуются: адрес, телефон и паспортные данные. Ты можешь эти данные внести самостоятельно. Если не хочешь заморачиваться, то можешь отправить свои данные нам, мы сами внесем их в карточку владельца доменов. На всякий случай напомню: мы твои данные не собираем, не храним, не продаем, нам твои персональные данные вообще не нужны.</p>

			<p>Аккаунты для тебя мы регистрируем сами и передаем доступы к аккаунтам тебе после завершения работы над сайтом и полного расчета. Затем, если ты не будешь заказывать у нас поддержку сайта, рекомендуем тебе поменять пароли.</p>

			<p>Вот какие у тебя будут аккаунты:</p>

			<p><a href='https://reg.ru/?rlink=reflink-6822007' rel='nofollow' target='_blank'>Reg.ru</a> - домены, хостинг, SSL-сертификат.</p>

			<p><a href='https://wpshop.ru/?partner=10577' rel='nofollow' target='_blank'>WPshop.ru</a> - шаблон и плагины для WordPress.</p>

			<h3>Домен. Хостинг. Шаблон и плагины.</h3>

			<p>После регистрации аккаунтов, первым делом, ты пополняешь свой счет в <a href='https://reg.ru/?rlink=reflink-6822007' rel='nofollow' target='_blank'>Reg.ru</a> на сумму необходимую для покупки домена(ов) и хостинга. Далее мы регистрируем домен(ы) и покупаем хостинг.</p>

			<p>Следующим шагом покупаем шаблон и плагины для Wordpress на сайте <a href='https://wpshop.ru/?partner=10577' rel='nofollow' target='_blank'>WPshop.ru</a></p>

			<p>После покупки шаблона и плагинов переходим к созданию и настройке твоего сайта.</p>

			<h3>Создание и настройка сайта</h3>

			<p>Вот как все происходит:</p>

			<ul>
				<li>Настраиваем хостинг под твой сайт.</li>
				<li>Настраиваем платформу сайта: устанавливаем WordPress, Шаблон и необходимые плагины.</li>
				<li>Настраиваем структуру сайта в соответствии с базовой структурой шаблона.</li>
				<li>Размещаем демо-данные (позже ты самостоятельно сможешь поменять их на свой контент)</li>
			</ul>

			<h3>Оплата</h3>

			<p>Основные суммы ты оплачиваешь напрямую через свои аккаунты (домен, хостинг, шаблон... ну, ты помнишь). Все это ты делаешь сразу, чтобы не затягивать процесс.</p>

			<p>Оставшуюся часть - 3'200 руб. - вознаграждение за нашу полезную работу ты переводишь нам после того, как сайт будет готов.</p>

			<h3>Финал и что дальше</h3>

			<p>Всё. Сайт готов. На этом наши работы завершены. А жизнь и развитие твоего сайта только начинается.</p>

			<p>После запуска сайта, обязательно подключи аналитику, чтобы видеть сколько посетителей заходит на сайт, какие страницы посещают и т.д. Наполняй полезным уникальным контентом - это основное. Ну и поддерживай сайт в здоровом состоянии: обновления, резервные копии и вовремя оплачивай домен(ы) и хостинг. Техническое сопровождение сайта, кстати, мы тоже можем взять на себя, если хочешь.</p>

			<p>Желаем успеха тебе в развитии сайта и достижении поставленных целей.</p>

			<h2>Как сделать такой сайт самостоятельно</h2>

			<p>Если вдруг тебе жалко немного денег для нас, но не жалко потратить свое время, то следуя простому алгоритму, описанному выше, ты можешь самостоятельно сделать такой сайт. Мы нисколько не обидимся и полностью тебя в этом поддерживаем. Можем даже дать консультацию за небольшое вознаграждение, если хочешь.</p>

			<blockquote>
				<p>Если остались вопросы - пиши нам. Как связаться смотри на странице <a href='/contacts'>Контакты</a></p>
			</blockquote>

		</ContentCenter>
	</Layout>
)

export default Wordpress